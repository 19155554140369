import {
  SEND_CONTACT_MESSAGE_LOADING,
  SEND_CONTACT_MESSAGE_SUCCESS,
  SEND_CONTACT_MESSAGE_FAIL,
} from "../actions/types"

const initialState = {
  isLoading: false,
  contact: null,
  errors: null,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case SEND_CONTACT_MESSAGE_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case SEND_CONTACT_MESSAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        contact: null,
        errors: null,
      }
    case SEND_CONTACT_MESSAGE_FAIL:
      return {
        ...state,
        isLoading: false,
        errors: action.payload.errors,
      }
    default:
      return state
  }
}
