import {
  STORE_ORDER_LOADING,
  STORE_ORDER_SUCCESS,
  STORE_ORDER_FAIL,
  GET_ORDERS_LOADING,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDER_DETAIL_LOADING,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDER_DETAIL_FAIL,
} from "../actions/types"

const initialState = {
  isLoading: false,
  orders: [],
  order: {},
  errors: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case STORE_ORDER_LOADING:
    case GET_ORDERS_LOADING:
    case GET_ORDER_DETAIL_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case STORE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case STORE_ORDER_FAIL:
      return {
        ...state,
        isLoading: false,
        errors: action.payload.errors,
      }
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orders: action.payload,
      }
    case GET_ORDERS_FAIL:
      return {
        ...state,
        isLoading: false,
        orders: [],
        errors: action.payload.errors,
      }
    case GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        order: action.payload,
      }
    case GET_ORDER_DETAIL_FAIL:
      return {
        ...state,
        isLoading: false,
        order: {},
        errors: action.payload.errors,
      }
    default:
      return state
  }
}
