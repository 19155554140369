import {
  GET_PRODUCTS_LOADING,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_BY_CATEGORY_LOADING,
  GET_PRODUCTS_BY_CATEGORY_SUCCESS,
  GET_PRODUCTS_BY_CATEGORY_FAIL,
  GET_FIRST_PRODUCTS_LOADING,
  GET_FIRST_PRODUCTS_SUCCESS,
  GET_FIRST_PRODUCTS_FAIL,
  ADD_TO_CART,
  REMOVE_TO_CART,
  INCREMENT_CART_PRODUCT,
  DECREMENT_CART_PRODUCT,
  EMPTY_CART,
} from "../actions/types"

const initialState = {
  isLoading: false,
  products: [],
  firstProducts: [],
  cart: [],
  countCart: 0,
  subTotalAmount: 0,
  shippingAmount: 2000,
  totalAmount: 0,
  productsByCategory: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS_LOADING:
    case GET_FIRST_PRODUCTS_LOADING:
    case GET_PRODUCTS_BY_CATEGORY_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: action.payload,
      }
    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        isLoading: false,
        products: [],
      }
    case GET_PRODUCTS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productsByCategory: action.payload,
      }
    case GET_PRODUCTS_BY_CATEGORY_FAIL:
      return {
        ...state,
        isLoading: false,
        productsByCategory: [],
      }
    case GET_FIRST_PRODUCTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        firstProducts: action.payload,
      }
    case GET_FIRST_PRODUCTS_FAIL:
      return {
        ...state,
        isLoading: false,
        firstProducts: [],
      }
    case ADD_TO_CART:
      let product = state.products.find(
        product => product.id === action.payload
      )
      let existedProduct = state.cart.find(
        cartProduct => cartProduct.id === action.payload
      )
      if (existedProduct) {
        Number((product.quantity += 1))
        return {
          ...state,
          subTotalAmount:
            Number(state.subTotalAmount) + Number(product.selling_price),
        }
      }
      product.quantity = 1
      let newSubTotalAmount =
        Number(state.subTotalAmount) + Number(product.selling_price)
      return {
        ...state,
        cart: [...state.cart, product],
        subTotalAmount: Number(newSubTotalAmount),
      }
    case REMOVE_TO_CART:
      let productToRemove = state.products.find(
        product => action.payload === product.id
      )
      let newCart = state.cart.filter(product => action.payload !== product.id)
      let newSTAmount =
        Number(state.subTotalAmount) -
        Number(productToRemove.selling_price) * Number(productToRemove.quantity)
      return {
        ...state,
        cart: newCart,
        subTotalAmount: Number(newSTAmount),
      }
    case INCREMENT_CART_PRODUCT:
      let productToIncrement = state.cart.find(
        product => action.payload === product.id
      )
      let newSubTotalAmountICP =
        Number(state.subTotalAmount) + Number(productToIncrement.selling_price)
      Number((productToIncrement.quantity += 1))
      return {
        ...state,
        subTotalAmount: Number(newSubTotalAmountICP),
      }
    case DECREMENT_CART_PRODUCT:
      let productToDecrement = state.products.find(
        product => action.payload === product.id
      )
      let newSubTotalAmountDCP =
        Number(state.subTotalAmount) - Number(productToDecrement.selling_price)
      if (productToDecrement.quantity === 1) {
        let newCart = state.cart.filter(
          product => product.id !== action.payload
        )
        return {
          ...state,
          subTotalAmount: Number(newSubTotalAmountDCP),
          cart: newCart,
        }
      } else {
        Number((productToDecrement.quantity -= 1))
        return {
          ...state,
          subTotalAmount: Number(newSubTotalAmountDCP),
        }
      }
    case EMPTY_CART:
      return {
        ...state,
        cart: [],
      }
    default:
      return state
  }
}
