// LOGIN ACTION TYPES
export const LOGIN_LOADING = "LOGIN_LOADING"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const REGISTER_LOADING = "REGISTER_LOADING"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"
export const GET_USER_LOADING = "GET_USER_LOADING"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAIL = "GET_USER_FAIL"
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"
export const LOGOUT_LOADING = "LOGOUT_LOADING"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAIL = "LOGOUT_FAIL"

// PAYMENT METHOD ACTION TYPES
export const GET_PAYMENT_METHODS_LOADING = "GET_PAYMENT_METHODS_LOADING"
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS"
export const GET_PAYMENT_METHODS_FAIL = "GET_PAYMENT_METHODS_FAIL"

// PRODUCT ACTION TYPES
export const GET_PRODUCTS_LOADING = "GET_PRODUCTS_LOADING"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"

// FIRST PRODUCT ACTION TYPES
export const GET_FIRST_PRODUCTS_LOADING = "GET_FIRST_PRODUCTS_LOADING"
export const GET_FIRST_PRODUCTS_SUCCESS = "GET_FIRST_PRODUCTS_SUCCESS"
export const GET_FIRST_PRODUCTS_FAIL = "GET_FIRST_PRODUCTS_FAIL"

// POST ACTION TYPES
export const GET_POSTS_LOADING = "GET_POSTS_LOADING"
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS"
export const GET_POSTS_FAIL = "GET_POSTS_FAIL"

// FIRST POST ACTION TYPES
export const GET_FIRST_POSTS_LOADING = "GET_FIRST_POSTS_LOADING"
export const GET_FIRST_POSTS_SUCCESS = "GET_FIRST_POSTS_SUCCESS"
export const GET_FIRST_POSTS_FAIL = "GET_FIRST_POSTS_FAIL"

// CART ACTION TYPES
export const ADD_TO_CART = "ADD_TO_CART"
export const REMOVE_TO_CART = "REMOVE_TO_CART"
export const INCREMENT_CART_PRODUCT = "INCREMENT_CART_PRODUCT"
export const DECREMENT_CART_PRODUCT = "DECREMENT_CART_PRODUCT"
export const EMPTY_CART = "EMPTY_CART"

// ADDRESS ACTION TYPES
export const GET_ADDRESSES_LOADING = "GET_ADDRESSES_LOADING"
export const GET_ADDRESSES_SUCCESS = "GET_ADDRESSES_SUCCESS"
export const GET_ADDRESSES_FAIL = "GET_ADDRESSES_FAIL"

// STORE ORDER ACTION TYPES
export const STORE_ORDER_LOADING = "STORE_ORDER_LOADING"
export const STORE_ORDER_SUCCESS = "STORE_ORDER_SUCCESS"
export const STORE_ORDER_FAIL = "STORE_ORDER_FAIL"

// GET ORDERS ACTION TYPES
export const GET_ORDERS_LOADING = "GET_ORDERS_LOADING"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"

// GET ORDER DETAIL ACTION TYPES
export const GET_ORDER_DETAIL_LOADING = "GET_ORDER_DETAIL_LOADING"
export const GET_ORDER_DETAIL_SUCCESS = "GET_ORDER_DETAIL_SUCCESS"
export const GET_ORDER_DETAIL_FAIL = "GET_ORDER_DETAIL_FAIL"

// GET POST DETAIL ACTION TYPES
export const GET_POST_DETAIL_LOADING = "GET_POST_DETAIL_LOADING"
export const GET_POST_DETAIL_SUCCESS = "GET_POST_DETAIL_SUCCESS"
export const GET_POST_DETAIL_FAIL = "GET_POST_DETAIL_FAIL"

// SEND_CONTACT_MESSAGE ACTION TYPES
export const SEND_CONTACT_MESSAGE_LOADING = "SEND_CONTACT_MESSAGE_LOADING"
export const SEND_CONTACT_MESSAGE_SUCCESS = "SEND_CONTACT_MESSAGE_SUCCESS"
export const SEND_CONTACT_MESSAGE_FAIL = "SEND_CONTACT_MESSAGE_FAIL"

// GET_CATEGORIES ACTION TYPES
export const GET_CATEGORIES_LOADING = "GET_CATEGORIES_LOADING"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"

// GET_CATEGORY ACTION TYPES
export const GET_CATEGORY_LOADING = "GET_CATEGORY_LOADING"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL"

// GET_PRODUCTS_BY_CATEGORY ACTION TYPES
export const GET_PRODUCTS_BY_CATEGORY_LOADING =
  "GET_PRODUCTS_BY_CATEGORY_LOADING"
export const GET_PRODUCTS_BY_CATEGORY_SUCCESS =
  "GET_PRODUCTS_BY_CATEGORY_SUCCESS"
export const GET_PRODUCTS_BY_CATEGORY_FAIL = "GET_PRODUCTS_BY_CATEGORY_FAIL"

// GET_FEEDBACKS ACTION TYPES
export const GET_FEEDBACKS_LOADING = "GET_FEEDBACKS_LOADING"
export const GET_FEEDBACKS_SUCCESS = "GET_FEEDBACKS_SUCCESS"
export const GET_FEEDBACKS_FAIL = "GET_FEEDBACKS_FAIL"

// GET_FIRST_FEEDBACKS ACTION TYPES
export const GET_FIRST_FEEDBACKS_LOADING = "GET_FIRST_FEEDBACKS_LOADING"
export const GET_FIRST_FEEDBACKS_SUCCESS = "GET_FIRST_FEEDBACKS_SUCCESS"
export const GET_FIRST_FEEDBACKS_FAIL = "GET_FIRST_FEEDBACKS_FAIL"
