import {
  GET_ADDRESSES_LOADING,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESSES_FAIL,
} from "../actions/types"

const initialState = {
  isLoading: false,
  addresses: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ADDRESSES_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case GET_ADDRESSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addresses: action.payload,
      }
    case GET_ADDRESSES_FAIL:
      return {
        ...state,
        isLoading: false,
        addresses: [],
      }
    default:
      return state
  }
}
