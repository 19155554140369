import {
  GET_POSTS_LOADING,
  GET_POSTS_SUCCESS,
  GET_POSTS_FAIL,
  GET_FIRST_POSTS_LOADING,
  GET_FIRST_POSTS_SUCCESS,
  GET_FIRST_POSTS_FAIL,
  GET_POST_DETAIL_LOADING,
  GET_POST_DETAIL_SUCCESS,
  GET_POST_DETAIL_FAIL,
} from "../actions/types"

const initialState = {
  isLoading: false,
  posts: [],
  firstPosts: [],
  post: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_POSTS_LOADING:
    case GET_FIRST_POSTS_LOADING:
    case GET_POST_DETAIL_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case GET_POSTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        posts: action.payload,
      }
    case GET_POSTS_FAIL:
      return {
        ...state,
        isLoading: false,
        posts: [],
      }
    case GET_FIRST_POSTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        firstPosts: action.payload,
      }
    case GET_FIRST_POSTS_FAIL:
      return {
        ...state,
        isLoading: false,
        firstPosts: [],
      }
    case GET_POST_DETAIL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        post: action.payload,
      }
    case GET_POST_DETAIL_FAIL:
      return {
        ...state,
        isLoading: false,
        post: {},
      }
    default:
      return state
  }
}
