import { combineReducers } from "redux"
import productReducer from "./productReducer"
import postReducer from "./postReducer"
import addressReducer from "./addressReducer"
import paymentMethodReducer from "./paymentMethodReducer"
import authReducer from "./authReducer"
import orderReducer from "./orderReducer"
import otherReducer from "./otherReducer"
import categoryReducer from "./categoryReducer"
import feedbackReducer from "./feedbackReducer"

const rootReducer = combineReducers({
  product: productReducer,
  post: postReducer,
  address: addressReducer,
  paymentMethod: paymentMethodReducer,
  auth: authReducer,
  order: orderReducer,
  other: otherReducer,
  category: categoryReducer,
  feedback: feedbackReducer,
})

export default rootReducer
