import React from "react"
import { Provider } from "react-redux"
import store from "./index"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default ({ element }) => (
  <Provider store={store}>
    {element}
    <ToastContainer style={{ width: "50%", textAlign: "center" }} />
  </Provider>
)
