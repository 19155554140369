import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_USER_LOADING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  LOGOUT_LOADING,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
} from "../actions/types"
const windowGlobal = typeof window !== "undefined" && window

const initialState = {
  token: windowGlobal ? windowGlobal.localStorage.getItem("token") : "",
  isAuthenticated: null,
  isLoading: false,
  user: null,
  errors: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_LOADING:
    case LOGIN_LOADING:
    case REGISTER_LOADING:
    case UPDATE_USER_LOADING:
    case LOGOUT_LOADING:
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
        errors: null,
      }
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      windowGlobal &&
        windowGlobal.localStorage.setItem("token", action.payload.token)
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
        errors: null,
      }
    case LOGIN_FAIL:
      windowGlobal && windowGlobal.localStorage.removeItem("token")
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        errors: action.payload.message,
      }
    case REGISTER_FAIL:
    case LOGOUT_SUCCESS:
      windowGlobal && windowGlobal.localStorage.removeItem("token")
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        errors: action.payload.errors,
      }
    case GET_USER_FAIL:
      windowGlobal && windowGlobal.localStorage.removeItem("token")
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        errors: null,
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        errors: null,
      }
    case UPDATE_USER_FAIL:
    case LOGOUT_FAIL:
      return {
        ...state,
        isLoading: false,
        errors: action.payload.message,
      }
    default:
      return state
  }
}
