/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/css/index.css"
// import "./src/css/custom.css"
import ReduxWrapper from "./src/store/ReduxWrapper"

export const wrapRootElement = ReduxWrapper
