// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-all-clothes-js": () => import("./../src/pages/all-clothes.js" /* webpackChunkName: "component---src-pages-all-clothes-js" */),
  "component---src-pages-bedding-clothes-js": () => import("./../src/pages/bedding-clothes.js" /* webpackChunkName: "component---src-pages-bedding-clothes-js" */),
  "component---src-pages-carpet-clothes-js": () => import("./../src/pages/carpet-clothes.js" /* webpackChunkName: "component---src-pages-carpet-clothes-js" */),
  "component---src-pages-cart-js": () => import("./../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-company-clothes-js": () => import("./../src/pages/company-clothes.js" /* webpackChunkName: "component---src-pages-company-clothes-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-man-clothes-js": () => import("./../src/pages/man-clothes.js" /* webpackChunkName: "component---src-pages-man-clothes-js" */),
  "component---src-pages-order-js": () => import("./../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-orders-js": () => import("./../src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-password-reset-js": () => import("./../src/pages/password-reset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-posts-js": () => import("./../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-profile-js": () => import("./../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-register-js": () => import("./../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-user-js": () => import("./../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-was-chair-clothes-js": () => import("./../src/pages/was-chair-clothes.js" /* webpackChunkName: "component---src-pages-was-chair-clothes-js" */),
  "component---src-pages-woman-clothes-js": () => import("./../src/pages/woman-clothes.js" /* webpackChunkName: "component---src-pages-woman-clothes-js" */)
}

