import {
  GET_FEEDBACKS_LOADING,
  GET_FEEDBACKS_SUCCESS,
  GET_FEEDBACKS_FAIL,
  GET_FIRST_FEEDBACKS_LOADING,
  GET_FIRST_FEEDBACKS_SUCCESS,
  GET_FIRST_FEEDBACKS_FAIL,
} from "../actions/types"

const initialState = {
  isLoading: false,
  feedbacks: [],
  firstFeedbacks: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FEEDBACKS_LOADING:
    case GET_FIRST_FEEDBACKS_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case GET_FEEDBACKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        feedbacks: action.payload,
      }
    case GET_FEEDBACKS_FAIL:
      return {
        ...state,
        isLoading: false,
        feedbacks: [],
      }
    case GET_FIRST_FEEDBACKS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        firstFeedbacks: action.payload,
      }
    case GET_FIRST_FEEDBACKS_FAIL:
      return {
        ...state,
        isLoading: false,
        firstFeedbacks: [],
      }
    default:
      return state
  }
}
