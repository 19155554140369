import {
  GET_PAYMENT_METHODS_LOADING,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAIL,
} from "../actions/types"

const initialState = {
  isLoading: false,
  paymentMethods: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENT_METHODS_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        paymentMethods: action.payload,
      }
    case GET_PAYMENT_METHODS_FAIL:
      return {
        ...state,
        isLoading: false,
        paymentMethods: [],
      }
    default:
      return state
  }
}
